@import "./variables.scss";

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  scroll-behavior: smooth;
  background-color: $secondary-color;
  position: relative;
  z-index: -0;
  font-family: $font-primary;
  font-weight: 400;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

body {
  cursor: url("../images/mouse.svg"), auto !important;
}
* {
  cursor: url("../images/mouse.svg"), auto !important;
}
a {
  font-family: $font-primary;
}

.btn_main {
  background: $primary-color;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all ease-in 0.1s;
  padding: 0 2rem;
  position: relative;
  color: #130214;
  font-family: $font-primary;

  &::after {
    content: " ";
    left: -5px;
    top: -5px;
    position: absolute;
    width: calc(100% + 10px);
    height: calc(100% + 10px);
    border: 1px solid $primary-color;
  }

  @media only screen and (max-width: 1400px) {
    width: 154px;
    height: 38px;
  }

  &:hover {
    background: $primary-color;
  }

  @media only screen and (max-width: 480px) {
    width: 150px;
    height: 35px;
  }

  button {
    color: #000;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    outline: none;
    border: none;
    background: transparent;
    cursor: pointer;
  }
}

.loader-modal {
  position: fixed;
  z-index: 9;
  background: rgba($secondary-color, 0.5);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom_alert_main_container {
  background: linear-gradient(
    180deg,
    rgb(255, 124, 61) 0%,
    rgb(151, 18, 255) 100%
  );
  border-radius: 5px;
  margin: 60px 20px;
  padding: 1px;
  // padding: 1px;
  .custom_alert_container {
    background: $secondary-color;
    margin: 0 !important;
    color: white;
    display: flex;
    align-items: center;
    padding: 15px !important;
    button {
      color: $primary-color;
      margin-left: 10px;
      background: none;
      border: none;
      cursor: pointer;
      .close_icon {
        height: 23px;
        width: 20px;
        div {
          height: 100%;
          svg {
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }
}
