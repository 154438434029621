@import "../../assets/scss/variables.scss";

.navbar_main_container {
  padding: 15px 0rem;
  background: #01191D;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
  background: rgba(19, 2, 20, 0.7);
  backdrop-filter: blur(5px);


  @media screen and (max-width:1400px) {
    padding: 10px 0;
  }

  &::after {
    content: ' ';
    position: absolute;
    bottom: 0;
    height: 1px;
    width: 100%;
    display: block;
    background: linear-gradient(90deg, rgba(255, 124, 61, 0) 0.59%, rgba(255, 124, 61, 0.32) 48.71%, rgba(255, 124, 61, 0) 100.7%);
  }

  .navbar_container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left_container {
      .logo_container {
        max-width: 150px;
        display: flex;
        align-items: center;

        @media screen and (max-width:1400px) {
          max-width: 100px;
        }

        .logo {
          height: 100%;
          width: 100%;
          cursor: pointer;
        }
      }
    }

    .right_container {
      display: flex;

      .btn_main {
          display: block;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          font-weight: 700;
          color: $white-color;
          font-size: 1.05rem;
          text-transform: uppercase;
          @media screen and (max-width:1400px) {
            height: 32px;
            padding: 0 1.6rem;
          }
          @media screen and (max-width:640px) {
            font-size: 0.95rem;
            height: 30px;
            padding: 0 1.5rem;
          }
      }

      .right-menu {
        display: flex;
        align-items: center;
        margin-right: 2rem;
        font-weight: 600;

        @media screen and (max-width:640px) {
          display: none;
        }

        a, span {
          color: $white-color;
          text-transform: uppercase;
          font-size: 1.1rem;
          text-decoration: none;
          padding: 0 1rem;
          transition: all ease-in 0.1s;
          font-weight: 700;
          cursor: pointer;
          @media screen and (max-width:1400px) {
            font-size: 0.95rem;
            padding: 0 0.8rem;
          }

          &:hover {
            color: $primary-color;
          }

          &.docs-link {
            position: relative;

            .tooltip {
              background: $primary-color;
              top: 33px;
              color: $secondary-color;
              display: block;
              margin-bottom: 0;
              left: -22px;
              margin: auto;
              opacity: 0;
              width: 140px;
              padding: 0.8rem 1.2rem;
              pointer-events: none;
              position: absolute;
              white-space: nowrap;
              -webkit-transform: translateY(10px);
              -moz-transform: translateY(10px);
              -ms-transform: translateY(10px);
              -o-transform: translateY(10px);
              transform: translateY(10px);
              -webkit-transition: all .25s ease-out;
              -moz-transition: all .25s ease-out;
              -ms-transition: all .25s ease-out;
              -o-transition: all .25s ease-out;
              transition: all .25s ease-out;
              font-size: 15px;
              font-weight: 400;

              &:after {
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-bottom: 10px solid $primary-color;
                top: -10px;
                content: " ";
                height: 0;
                left: 50%;
                margin-left: -13px;
                position: absolute;
                width: 0;
              }
            }

            &:hover {
              .tooltip {
                opacity: 1;
                pointer-events: auto;
                -webkit-transform: translateY(0px);
                -moz-transform: translateY(0px);
                -ms-transform: translateY(0px);
                -o-transform: translateY(0px);
                transform: translateY(0px);
              }
            }

          }
        }
      }
    }
  }
}
