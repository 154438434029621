@import url('https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap');

// colors
$primary-color: #FF7C3D;
$secondary-color: #130214;
$white-color: #ffffff;
$font-primary: "Play", sans-serif;
$text-secondary: rgba($white-color, 0.7);
$dark-color: #000E0E;

// Bagckground color