@import "../../assets/scss/variables.scss";

.footer-main {
  background-image: url('../../assets/images/footer-bg.jpg');
  width: 100%;
  padding: 20px 15px 0 15px;

  @media screen and (max-width:640px) {
    padding: 20px 0 0 0;
  }

  .footer-upper {
    display: flex;
    justify-content: space-between;
    .left-upper {
      img {
        max-width: 160px;
        margin-bottom: 0.5rem;
        @media screen and (max-width:640px) {
          max-width: 100px;
        }
      }
      p {
        color: $white-color;
        font-size: 1rem;
        font-weight: 400;
        @media screen and (max-width:640px) {
          font-size: 0.85rem;
        }
      }
    }
    .right-upper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      a {
        padding: 0 10px;
        transition: all ease-in 0.1s;
        &:hover {
          transform: scale(1.2);
        }
        svg {
          fill: $white-color;
          @media screen and (max-width:640px) {
            max-width: 18px;
          }
        }
      }
    }
  }

  .copyright {
    color: $white-color;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.1;
    letter-spacing: 0.5px;
    position: relative;
    padding: 1rem 15px;
    margin-top: 1.5rem;
    &::before {
      content: " ";
      position: absolute;
      width: 90%;
      height: 1.2px;
      margin: auto;
      top: 0;
      left: 0;
      right: 0;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 2.5%, #FFFFFF 56.5%, rgba(255, 255, 255, 0) 100%);
      opacity: 0.8;
    }
    a {
      color: $white-color;
      text-decoration: none;
      transition: all ease-in 0.1s;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}