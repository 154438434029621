@import "../../assets/scss/variables.scss";

.container {
  width: 1440px;
  max-width: 94%;
  margin: auto;

  @media screen and (max-width: 991px) {
    max-width: 92%;
  }
}

.bee-animation {
  position: absolute;
  left: 20px;
  bottom: -15px;
  z-index: 8;
  @media screen and (max-width: 991px) {
    transform: scale(0.7);
    left: 0;
  }
}

.headings {
  text-align: center;
  margin-bottom: 2rem;

  h1 {
    color: $white-color;
    font-size: 4.2rem;
    margin-bottom: 2rem;
    font-weight: 700;
    position: relative;
    display: inline-block;

    &::after {
      content: " ";
      position: absolute;
      width: 90%;
      height: 1.2px;
      margin: auto;
      bottom: -8px;
      left: 0;
      right: 0;
      background: linear-gradient(
        90deg,
        rgba(255, 124, 61, 0) 0%,
        #ff7c3d 50.12%,
        rgba(255, 124, 61, 0) 98.5%
      );
    }

    @media screen and (max-width: 1600px) {
      font-size: 3.6rem;
      margin-bottom: 1.4rem;
    }

    @media screen and (max-width: 1400px) {
      font-size: 3rem;
      margin-bottom: 1.3rem;
    }

    @media screen and (max-width: 1024px) {
      font-size: 3rem;
    }

    @media screen and (max-width: 991px) {
      font-size: 2.5rem;
    }

    @media screen and (max-width: 767px) {
      font-size: 2rem;
      line-height: 1.2;
    }

    span {
      color: $primary-color;
      background-image: linear-gradient(
        -210deg,
        #ff7c3d 0%,
        #7d72bd 29%,
        #ff7c3d 67%,
        #ffc89e 100%
      );
      background-size: auto auto;
      background-clip: border-box;
      background-size: 200% auto;
      color: #fff;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-text-fill-color: transparent;
      text-fill-color: transparent;
      animation: textclip 2s linear infinite;
      display: inline-block;
    }
  }

  p {
    color: $white-color;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.5;

    @media screen and (max-width: 1600px) {
      font-size: 1.1rem;
    }

    @media screen and (max-width: 1400px) {
      font-size: 1rem;
    }

    @media screen and (max-width: 1024px) {
      font-size: 1rem;
    }

    @media screen and (max-width: 767px) {
      font-size: 0.9rem;

      br {
        display: none;
      }
    }
  }
}

@keyframes flicker {
  0%,
  2%,
  35%,
  39%,
  41%,
  100% {
    opacity: 1;
  }
  1%,
  3%,
  34%,
  36%,
  38%,
  40% {
    opacity: 0;
  }
}

.hero_section_main_container {
  width: 100%;
  position: relative;
  overflow: hidden;
  min-height: 800px;
  display: flex;
  background: #000e0e;

  @media screen and (max-width: 1660px) {
    min-height: 700px;
  }

  @media screen and (max-width: 1400px) {
    min-height: 600px;
    top: 56px;
  }

  @media screen and (max-width: 1024px) {
    min-height: 600px;
  }

  @media screen and (max-width: 767px) {
    min-height: 500px;
  }

  &::before {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      180deg,
      #130214 0%,
      rgba(60, 3, 56, 0.75) 23.04%,
      rgba(2, 23, 16, 0.6) 66.67%,
      rgba(2, 23, 16, 0.03) 100%
    );
    transform: rotate(-180deg);
    position: absolute;
    z-index: 1;
  }

  .background-video {
    position: absolute;
    width: 100vw;
    height: 100%;
    object-fit: cover;
    opacity: 0.8;
  }

  .hero_section_container {
    height: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 6rem;

    @media screen and (max-width: 1400px) {
      padding-top: 0;
    }

    .text_button_container {
      height: 100%;
      text-align: center;

      .headings {
        h1 {
          font-size: 5.5rem;
          line-height: 1.2;
          margin-bottom: 0.5rem;
          text-align: center;

          .mr-2 {
            margin-right: 1.3rem;
          }

          label {
            color: $primary-color;
          }

          .letters {
            animation: flicker 6s ease-in-out 8.15s infinite alternate both;
            &.letter-1 {
              animation: flicker 6s ease-in-out 2s infinite alternate both;
            }
            &.letter-2 {
              animation: flicker 6s ease-in-out 2.5s infinite alternate both;
            }
            &.letter-3 {
              animation: flicker 6s ease-in-out 3.5s infinite alternate both;
            }
            &.letter-4 {
              animation: flicker 6s ease-in-out 4.5s infinite alternate both;
            }
            &.letter-5 {
              animation: flicker 6s ease-in-out 5.5s infinite alternate both;
            }
            &.letter-6 {
              animation: flicker 6s ease-in-out 4s infinite alternate both;
            }
            &.letter-7 {
              animation: flicker 6s ease-in-out 5.5s infinite alternate both;
            }
            &.letter-8 {
              animation: flicker 6s ease-in-out 3.5s infinite alternate both;
            }
            &.letter-9 {
              animation: flicker 6s ease-in-out 4.5s infinite alternate both;
            }
            &.letter-10 {
              animation: flicker 6s ease-in-out 3s infinite alternate both;
            }
            &.letter-11 {
              animation: flicker 6s ease-in-out 4.2s infinite alternate both;
            }
            &.letter-12 {
              animation: flicker 6s ease-in-out 6s infinite alternate both;
            }
            &.letter-13 {
              animation: flicker 6s ease-in-out 7.1s infinite alternate both;
            }
          }

          &::after {
            display: none;
          }

          @media screen and (max-width: 1400px) {
            font-size: 4rem;
            line-height: 1.1;
          }

          @media screen and (max-width: 1024px) {
            font-size: 4rem;
            line-height: 1.1;
          }

          @media screen and (max-width: 767px) {
            font-size: 3rem;
          }

          @media screen and (max-width: 576px) {
            font-size: 1.8rem;
          }
        }

        .banner2-row {
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            margin: 0 5px -5px 8px;
            max-height: 58px;
            @media screen and (max-width: 1400px) {
              max-height: 50px;
            }
            @media screen and (max-width: 767px) {
              max-height: 30px;
            }
            @media screen and (max-width: 576px) {
              max-height: 22px;
            }
          }
        }
      }

      .comming-soon {
        display: inline-block;
        color: $white-color;
        font-size: 1.5rem;
        font-weight: normal;
        margin: auto;

        @media screen and (max-width: 640px) {
          font-size: 1.3rem;
        }

        .hr {
          max-width: 94%;
          height: 3px;
          background: $primary-color;
          display: block;
          margin: 2px auto auto auto;
          animation-name: wisthAnim;
          animation-iteration-count: infinite;
          animation-direction: alternate;
          animation-duration: 1.8s;
        }
      }
    }
  }
}

@keyframes wisthAnim {
  from {
    width: 5%;
  }

  to {
    width: 90%;
  }
}

@keyframes rotateAnim {
  from {
    transform: rotate(-360deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotateAnimUndo {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

@keyframes rotateAnimcross {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ourecosystem-section {
  padding: 5rem 0 5rem 0;
  background-size: 100%;
  position: relative;

  .element-1 {
    position: absolute;
    left: -40px;
    max-width: 150px;
    top: -8%;
    animation-name: rotateAnim;
    animation-duration: 8s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    z-index: -1;
    @media screen and (max-width: 991px) {
      max-width: 80px;
    }
  }

  .element-2 {
    position: absolute;
    left: 5%;
    top: 6%;
    max-width: 100px;
    animation-name: rotateAnimUndo;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    z-index: -1;
    @media screen and (max-width: 991px) {
      max-width: 60px;
    }
  }

  .hexa-right {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    max-height: 700px;
    opacity: 0.2;
    z-index: 0;
    @media screen and (max-width: 1260px) {
      max-height: 500px;
    }
    @media screen and (max-width: 767px) {
      max-height: 400px;
      right: -20%;
    }
  }

  .hexa-left {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    max-height: 700px;
    opacity: 0.2;
    z-index: 0;
    @media screen and (max-width: 1260px) {
      max-height: 500px;
    }
    @media screen and (max-width: 767px) {
      max-height: 400px;
      left: -20%;
    }
  }

  .container {
    position: relative;
    z-index: 1;
  }

  .headings {
    p {
      max-width: 700px;
      margin: auto;
    }
  }

  .ecosystem-row {
    text-align: center;
    margin-top: 3rem;
    position: relative;
    z-index: 1;

    img {
      width: 410px;
      max-width: 100%;

      @media screen and (max-width: 1600px) {
        width: 380px;
      }

      @media screen and (max-width: 1400px) {
        max-width: 320px;
      }
    }
  }
}

.getintouch-section {
  padding: 5rem 0 11rem 0;
  position: inherit;
  .element-1 {
    position: absolute;
    left: 25px;
    max-width: 110px;
    top: 8%;
    z-index: -1;
    @media screen and (max-width: 991px) {
      max-width: 90px;
      top: 12%;
    }
  }

  .element-2 {
    position: absolute;
    left: -22px;
    top: 15%;
    max-width: 110px;
    z-index: -1;
    @media screen and (max-width: 991px) {
      max-width: 80px;
    }
  }

  .element-3-1 {
    position: absolute;
    right: 4%;
    max-width: 40px;
    bottom: 11%;
    animation-name: rotateAnimcross;
    animation-duration: 5s;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    z-index: -1;
    @media screen and (max-width: 991px) {
      max-width: 50px;
    }
  }

  .element-3-2 {
    position: absolute;
    right: 1%;
    max-width: 40px;
    bottom: 19%;
    z-index: -1;
    @media screen and (max-width: 991px) {
      max-width: 50px;
    }
  }

  .getinform {
    background: rgb(255, 124, 61);
    background: linear-gradient(
      180deg,
      rgba(255, 124, 61, 1) 0%,
      rgba(151, 18, 255, 1) 100%
    );
    border-radius: 10px;
    padding: 1px;
    max-width: 780px;
    margin: 2.2rem auto;
    .getinform-inner {
      background: #1f0f20;
      width: 100%;
      border-radius: 10px;
      padding: 3rem 2.5rem;
      text-align: left;
      .form-row {
        margin-bottom: 1.8rem;
      }
      label {
        color: $white-color;
        display: block;
        font-size: 1.3rem;
        margin-bottom: 0.6rem;
        font-weight: 400;
      }
      input {
        border: 1px solid rgba($primary-color, 0.5);
        height: 50px;
        border-radius: 2px;
        width: 100%;
        background: #1f0f20;
        outline: none;
        color: $white-color;
        padding-left: 15px;
        padding-right: 15px;
      }
      textarea {
        border: 1px solid rgba($primary-color, 0.5);
        border-radius: 2px;
        width: 100%;
        background: #1f0f20;
        outline: none;
        resize: none;
        color: $white-color;
        padding: 15px;
      }
      .btn_main {
        width: 100%;
        color: $white-color;
        font-size: 1.1rem;
        border: 0;
        text-transform: uppercase;
        font-weight: 700;
        margin-top: 2.1rem;
        &:disabled {
          opacity: 0.7;
          cursor: not-allowed;
        }
      }
    }
  }
}

.buildowngame-section {
  padding: 10rem 0;
  background-size: 100%;
  position: relative;

  @media screen and (max-width: 1400px) {
    padding: 6rem 0;
  }

  @media screen and (max-width: 991px) {
    padding: 3rem 0;
  }

  .element-1 {
    position: absolute;
    right: 25px;
    max-width: 110px;
    top: 8%;
    z-index: -1;
    @media screen and (max-width: 991px) {
      max-width: 90px;
      top: 12%;
    }
  }

  .element-2 {
    position: absolute;
    right: -22px;
    top: 15%;
    max-width: 110px;
    z-index: -1;
    @media screen and (max-width: 991px) {
      max-width: 80px;
    }
  }

  .headings {
    p {
      max-width: 780px;
      margin: auto;
      font-weight: 400;
      letter-spacing: 1px;
    }
  }

  .buildowngame-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.8rem;
    padding-top: 3rem;

    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(2, 1fr);
      padding-top: 1rem;
    }

    @media screen and (max-width: 640px) {
      grid-template-columns: repeat(1, 1fr);
    }

    .buildowngame-item {
      background-size: 100% 100%;
      background: linear-gradient(180deg, #ff7c3d 0%, #9712ff 100%);
      padding: 1px;
      transition: all ease-in 0.2s;

      &:hover {
        transform: scale(1.05);
      }

      .buildowngame-item-inner {
        min-height: 520px;
        padding: 2rem;
        background: linear-gradient(
          180deg,
          rgba(51, 0, 91, 0) -6.62%,
          rgba(51, 0, 91, 0.617261) 50.09%,
          #33005b 100%
        );
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media screen and (max-width: 640px) {
          min-height: 440px;
        }

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          opacity: 0.3;
          z-index: 0;
        }

        .card-upper {
          display: block;
        }

        .card-bottom {
          position: relative;
          z-index: 1;
          min-height: 180px;

          h3 {
            color: $white-color;
            font-weight: 600;
            font-size: 2rem;
            text-transform: uppercase;
            letter-spacing: 1.5px;
            margin-bottom: 1.4rem;
            position: relative;

            &::after {
              content: " ";
              position: absolute;
              width: 90%;
              height: 2.67px;
              left: 0;
              bottom: -5px;
              background: linear-gradient(
                90deg,
                #ff7c3d 0%,
                rgba(255, 124, 61, 0) 100%
              );
            }

            @media screen and (max-width: 991px) {
              font-size: 1.2rem;
            }

            span {
              color: $primary-color;
            }
          }

          p {
            color: $white-color;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.4;

            @media screen and (max-width: 991px) {
              font-size: 0.85rem;
            }

            a {
              color: #52f7ff;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}

.gamefi-section {
  padding: 6rem 0 6rem 0;
  background-position: left top;
  position: relative;

  @media screen and (max-width: 1400px) {
    padding: 6rem 0;
  }

  @media screen and (max-width: 767px) {
    padding: 4rem 0;
  }

  .element-1 {
    position: absolute;
    left: 10%;
    max-width: 80px;
    top: 8%;
    z-index: -1;
    @media screen and (max-width: 991px) {
      max-width: 30px;
      left: 5%;
      top: 5%;
    }
  }

  .element-2 {
    position: absolute;
    right: 1%;
    top: 18%;
    max-width: 85px;
    z-index: -1;
    @media screen and (max-width: 991px) {
      max-width: 40px;
    }
  }

  .element-3 {
    position: absolute;
    left: 0;
    max-width: 130px;
    top: 0;
    z-index: -1;
    @media screen and (max-width: 991px) {
      max-width: 60px;
    }
  }

  .element-4-1 {
    position: absolute;
    left: 3%;
    max-width: 50px;
    top: 12%;
    animation-name: rotateAnimcross;
    animation-duration: 5s;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    z-index: -1;
    @media screen and (max-width: 991px) {
      max-width: 30px;
      top: 5%;
    }
  }

  .element-4-2 {
    position: absolute;
    left: 9%;
    max-width: 30px;
    top: 7%;
    z-index: -1;
    @media screen and (max-width: 991px) {
      max-width: 20px;
      top: 2%;
    }
  }

  .element-5 {
    position: absolute;
    right: 1.5%;
    bottom: 8%;
    max-width: 75px;
    z-index: -1;
    @media screen and (max-width: 991px) {
      max-width: 30px;
    }
  }

  .container {
    position: relative;
    z-index: 1;
  }

  .rows {
    display: flex;
    align-items: center;

    @media screen and (max-width: 600px) {
      flex-direction: column;
    }

    &.rows-2 {
      @media screen and (max-width: 600px) {
        flex-direction: column-reverse;
      }
    }

    .col-md-6 {
      width: 50%;
      @media screen and (max-width: 600px) {
        width: 100%;
      }
    }

    .img-col {
      img {
        max-width: 550px;
        width: 100%;
      }

      &.img-col-right {
        text-align: right;
      }
    }
  }

  .points-list {
    list-style-type: none;
    padding-left: 0;
    max-width: 780px;
    padding-top: 2rem;

    @media screen and (max-width: 1600px) {
      padding-top: 1rem;
    }

    li {
      display: flex;
      font-size: 1.5rem;
      color: $white-color;
      align-items: center;
      padding: 1.5rem 0;

      @media screen and (max-width: 1600px) {
        font-size: 1.5rem;
        padding: 1.2rem 0;
      }

      @media screen and (max-width: 1400px) {
        padding: 1rem 0;
        font-size: 1.2rem;
      }

      @media screen and (max-width: 767px) {
        font-size: 1.2rem;
        padding: 1.1rem 0;
      }

      .left-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        background-repeat: no-repeat;
        background-size: 100%;
        width: 40px;
        height: 40px;
        min-width: 40px;
        margin-right: 1.4rem;

        @media screen and (max-width: 1600px) {
          width: 40px;
          height: 40px;
          min-width: 40px;
        }

        @media screen and (max-width: 767px) {
          width: 38px;
          height: 38px;
          min-width: 38px;
          margin-right: 1.1rem;
        }

        svg {
          @media screen and (max-width: 1600px) {
            max-width: 38px;
            max-height: 38px;
          }
        }
      }
    }
  }
}

.footer_main_container {
  position: absolute;
  width: 100%;
  bottom: 0;

  .footer_container {
    .footer {
      padding-left: 10px;
      padding-bottom: 2rem;

      .footer_column_container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 2rem;

        .footer_column {
          .title {
            color: $secondary-color;
            font-family: Teko;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            text-transform: uppercase;
          }

          .footer_rows_container {
            .footer_rows {
              .rows {
                color: #e8fffe;
                font-family: Rajdhani;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 28px;
                opacity: 0.5;
                cursor: not-allowed;

                a {
                  text-decoration: none;
                  color: inherit;
                }
              }
            }
          }
        }
      }
    }
  }
}

@keyframes textclip {
  to {
    background-position: -200% center;
  }
}
